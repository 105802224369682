var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.initData && _vm.initData.length > 0)?_c('b-row',{staticClass:"form-box"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-nav',{staticClass:"form-section",attrs:{"tabs":""}},[_c('b-nav-item',{attrs:{"disabled":""}},[_vm._v("Logistics Information")])],1)],1),_vm._l((_vm.initData),function(itemz,indexz){return _c('b-col',{key:indexz,staticClass:"son-from",attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h2',[_vm._v("Delivery "+_vm._s(indexz + 1))])]),_vm._l((itemz.list),function(item,index){return _c('b-col',{key:index,class:{
            'my-1': item.type != null && item.text != null
          },attrs:{"md":item.md || 6}},[(item.show != false)?[(item.type != null)?_c('b-form-group',{staticClass:"search-flex",class:[
                { required: item.required },
                item.class,
                {
                  form_error:
                    item.required &&
                    _vm.formError &&
                    (item.model == null || item.model == '')
                }
              ],attrs:{"label":item.text,"id":item.id,"label-for":'form-' + index,"label-cols-sm":"4","label-cols-lg":"3"}},[(item.type == 'input')?[_c('b-input-group',{class:{ no_r: !item.icon },scopedSlots:_vm._u([{key:"append",fn:function(){return [(item.icon || item.icon_text)?_c('b-input-group-text',[(item.icon)?_c('b-icon',{attrs:{"icon":item.icon}}):_vm._e(),_vm._v(" "+_vm._s(item.icon_text)+" ")],1):_vm._e()]},proxy:true}],null,true)},[(item.show != null)?_c('span',[_vm._v(_vm._s(item.show.key))]):_vm._e(),_c('b-form-input',{attrs:{"type":item.input_type || 'text',"disabled":item.disabled,"placeholder":item.disabled
                        ? ''
                        : item.placeholder || _vm.$t('page.please_choose_input')},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)]:(item.type == 'select')?[(item.options.length == 0)?_c('SelectLoading',{attrs:{"size":0.7}}):_vm._e(),_c('sv-select',{attrs:{"disabled":item.options == 0 || item.disabled,"clearable":item.clearable,"placeholder":item.disabled
                      ? ''
                      : item.placeholder || _vm.$t('page.please_choose'),"options":item.options,"reduce":function (options) { return options.value; },"multiple":item.multiple,"selectable":function (options) { return !options.selectable; },"label":"label"},on:{"input":function($event){return item.inputFn($event, indexz)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return (option.index)?[_c('div',{staticClass:"d-center",class:'level_' + option.index},[(option.index == 2)?_c('span',[_vm._v("--")]):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")])]:undefined}}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:(item.type == 'date')?[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(
                        !item.disabled &&
                        item.model != null &&
                        item.model != ''
                      )?_c('div',{staticClass:"datepicker-x",on:{"click":function($event){return _vm.initialTime(item, 'model')}}},[_c('b-icon-x',{attrs:{"font-scale":"1.45"}})],1):_vm._e(),_c('b-input-group-text',[_c('b-icon-calendar2-date')],1)]},proxy:true}],null,true)},[_c('b-form-datepicker',{attrs:{"date-format-options":{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    },"hide-header":true,"placeholder":item.disabled ? '' : item.placeholder,"disabled":item.disabled,"locale":"de"},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)]:_vm._e(),(item.type == 'textarea')?[_c('b-form-textarea',{attrs:{"id":item.id,"rows":item.rows,"placeholder":item.disabled
                      ? ''
                      : item.placeholder || _vm.$t('page.please_choose_textarea'),"disabled":item.disabled},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:_vm._e()],2):_vm._e()]:_vm._e()],2)}),(0)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3"}},[_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":{
                name: 'article-review-detail-index',
                params: {
                  article_review_id: itemz.article_review_id
                }
              }}},[_vm._v("Review")])],1)],1):_vm._e(),(0)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3"}},[(!itemz.list.logistics.disabled)?_c('b-button',{attrs:{"variant":"primary","disabled":itemz.list.logistics.disabled},on:{"click":function($event){return _vm.receiption(itemz.id)}}},[_vm._v("Receiption")]):_vm._e()],1)],1):_vm._e()],2)],1)})],2):_vm._e(),_c('b-modal',{ref:"modal",attrs:{"centered":"","no-close-on-backdrop":"","scrollable":"","title":"Delivery Reception","id":"invoice_detail"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
              var close = ref.close;
return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.submitDisabled},on:{"click":function($event){return _vm.yes()}}},[_vm._v(" YES ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return close()}}},[_vm._v(" NO ")])]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('p',{staticClass:"reconfirm"},[_vm._v("Are you sure that the delivery is received?")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }