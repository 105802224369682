<template>
  <div>
    <!-- <p v-if="initData">{{ Object.keys(initData) }}</p> -->
    <b-row class="form-box" v-if="initData && initData.length > 0">
      <b-col md="12">
        <b-nav tabs class="form-section">
          <b-nav-item disabled>Logistics Information</b-nav-item>
        </b-nav>
      </b-col>
      <!-- {{ op }} -->
      <b-col
        md="12"
        v-for="(itemz, indexz) in initData"
        :key="indexz"
        class="son-from"
      >
        <!-- {{ item }} -->
        <b-row>
          <b-col md="12">
            <h2>Delivery {{ indexz + 1 }}</h2>
          </b-col>

          <b-col
            :md="item.md || 6"
            v-for="(item, index) in itemz.list"
            :key="index"
            :class="{
              'my-1': item.type != null && item.text != null
            }"
          >
            <template v-if="item.show != false">
              <b-form-group
                v-if="item.type != null"
                :label="item.text"
                :id="item.id"
                :label-for="'form-' + index"
                class="search-flex"
                v-bind:class="[
                  { required: item.required },
                  item.class,
                  {
                    form_error:
                      item.required &&
                      formError &&
                      (item.model == null || item.model == '')
                  }
                ]"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <!-- input -->
                <template v-if="item.type == 'input'">
                  <b-input-group :class="{ no_r: !item.icon }">
                    <template v-slot:append>
                      <b-input-group-text v-if="item.icon || item.icon_text">
                        <b-icon v-if="item.icon" :icon="item.icon"></b-icon>
                        {{ item.icon_text }}
                      </b-input-group-text>
                    </template>
                    <span v-if="item.show != null">{{ item.show.key }}</span>

                    <b-form-input
                      v-model="item.model"
                      :type="item.input_type || 'text'"
                      :disabled="item.disabled"
                      :placeholder="
                        item.disabled
                          ? ''
                          : item.placeholder || $t('page.please_choose_input')
                      "
                    ></b-form-input>
                    <!-- -->
                  </b-input-group>
                </template>
                <!-- 下拉框 -->
                <template v-else-if="item.type == 'select'">
                  <SelectLoading
                    v-if="item.options.length == 0"
                    :size="0.7"
                  ></SelectLoading>
                  <!-- -->
                  <sv-select
                    v-model="item.model"
                    :disabled="item.options == 0 || item.disabled"
                    :clearable="item.clearable"
                    :placeholder="
                      item.disabled
                        ? ''
                        : item.placeholder || $t('page.please_choose')
                    "
                    :options="item.options"
                    :reduce="(options) => options.value"
                    :multiple="item.multiple"
                    :selectable="(options) => !options.selectable"
                    @input="item.inputFn($event, indexz)"
                    label="label"
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                      v-if="option.index"
                    >
                      <div class="d-center" :class="'level_' + option.index">
                        <span v-if="option.index == 2">--</span>
                        {{ option.label }}
                      </div>
                    </template>
                  </sv-select>
                </template>
                <!-- 时间组件-->
                <template v-else-if="item.type == 'date'">
                  <b-input-group>
                    <template v-slot:append>
                      <div
                        v-if="
                          !item.disabled &&
                          item.model != null &&
                          item.model != ''
                        "
                        class="datepicker-x"
                        @click="initialTime(item, 'model')"
                      >
                        <b-icon-x font-scale="1.45"></b-icon-x>
                      </div>
                      <b-input-group-text>
                        <b-icon-calendar2-date></b-icon-calendar2-date>
                      </b-input-group-text>
                    </template>
                    <b-form-datepicker
                      v-model="item.model"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                      }"
                      :hide-header="true"
                      :placeholder="item.disabled ? '' : item.placeholder"
                      :disabled="item.disabled"
                      locale="de"
                    ></b-form-datepicker>
                  </b-input-group>
                </template>
                <template v-if="item.type == 'textarea'">
                  <b-form-textarea
                    :id="item.id"
                    :rows="item.rows"
                    :placeholder="
                      item.disabled
                        ? ''
                        : item.placeholder || $t('page.please_choose_textarea')
                    "
                    :disabled="item.disabled"
                    v-model="item.model"
                  ></b-form-textarea>
                </template>
              </b-form-group>
            </template>
          </b-col>
          <b-col md="6" v-if="0">
            <b-form-group label-cols-sm="4" label-cols-lg="3">
              <router-link
                :to="{
                  name: 'article-review-detail-index',
                  params: {
                    article_review_id: itemz.article_review_id
                  }
                }"
                class="btn btn-primary btn-sm"
                >Review</router-link
              >
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="0">
            <b-form-group label-cols-sm="4" label-cols-lg="3">
              <b-button
                variant="primary"
                v-if="!itemz.list.logistics.disabled"
                :disabled="itemz.list.logistics.disabled"
                @click="receiption(itemz.id)"
                >Receiption</b-button
              >
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Delivery Reception"
      id="invoice_detail"
      ref="modal"
    >
      <b-overlay :show="loading">
        <p class="reconfirm">Are you sure that the delivery is received?</p>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button variant="primary" @click="yes()" :disabled="submitDisabled">
          YES
        </b-button>
        <b-button variant="secondary" @click="close()"> NO </b-button>
      </template></b-modal
    >
  </div>

  <!-- <Others></Others> -->
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import ReferenceLink from '@/components/ReferenceLink/Index';
import SelectLoading from '@/components/Loading/Index';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
export default {
  name: 'PurchaseoOverviewLogisticsInformation',

  data() {
    return {
      showModal: false,
      loading: false,
      submitDisabled: false,
      delivery_id: null
    };
  },
  props: {
    initData: Array,
    options: Object,
    formError: Boolean,
    initDataFn: Function
  },
  inheritAttrs: false,
  components: {
    // ReferenceLink
    SelectLoading
    // MerchantPartner
    // Others
  },
  methods: {
    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    },
    receiption(id) {
      this.showModal = true;
      this.delivery_id = id;
    },
    yes() {
      // this.initDataFn();
      this.loading = true;
      this.submitDisabled = true;
      apiBus.purchase
        .purchaseOverviewDeliveryReception({
          delivery_id: this.delivery_id
        })
        .then((data) => {
          console.log(data);
          this.loading = false;
          this.submitDisabled = false;
          this.showModal = false;
          this.initDataFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          this.loading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    }
  },

  computed: {},
  mounted() {}
};
</script>
<style scoped>
.image-box .bv-no-focus-ring {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  max-width: 100%;
  max-height: 170px;
}
h2 {
  font-size: 15px;
  padding-left: 20px;
}
</style>
